<template>
  <div :class="`${orientation} card`">
    <div class="card-content">
      <div class="img">
        <img src="https://via.placeholder.com/200x200?text=adomicilio"
             alt="A domicilio"/>
        <button class="" v-on:click="$emit('visible', true)">Ver más</button>
        <button v-if="item.favButton && orientation === 'vertical'" class="btn-clean btn-icon">
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.5 0C12.76 0 11.09 0.81 10 2.09C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.42 0 5.5C0 9.28 3.4 12.36 8.55 17.04L10 18.35L11.45 17.03C16.6 12.36 20 9.28 20 5.5C20 2.42 17.58 0 14.5 0ZM10.1 15.55L10 15.65L9.9 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 2.99 9.07 4.36H10.94C11.46 2.99 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55Z"
              fill="white"/>
          </svg>
        </button>
      </div>
      <div class="info">
        <button v-if="item.favButton && orientation === 'horizontal'" class="btn-clean btn-icon">
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.5 0C12.76 0 11.09 0.81 10 2.09C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.42 0 5.5C0 9.28 3.4 12.36 8.55 17.04L10 18.35L11.45 17.03C16.6 12.36 20 9.28 20 5.5C20 2.42 17.58 0 14.5 0ZM10.1 15.55L10 15.65L9.9 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 2.99 9.07 4.36H10.94C11.46 2.99 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55Z"
              fill="white"/>
          </svg>
        </button>
        <p class="label color-secondary mt-0">{{ item.name }}</p>
        <p v-if="item.description" class="alt">{{ item.description }}</p>
        <h4 v-if="item.price" class="mb-05 mt-05 color-primary">${{ item.price }}</h4>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ACard',
  props: {
    orientation: {
      default: 'vertical' // Or horizontal
    },
    item: {
      default: {
        name: 'Nombre',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab consequuntur magni ratione repellendus repudiandae? Alias asperiores atque',
        price: 0,
        favButton: true,
        onCard: 0
      }
    }
  }
}
</script>
